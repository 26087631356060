import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Attendee } from 'common/api/proctorType';
import AttendeePane from 'common/components/AttendeePane';
import TabPane from 'common/components//TabPane';
import { openModal } from 'common/slice/modalSlice';

import { SpeakerXMarkIcon, SpeakerWaveIcon, MegaphoneIcon } from '@heroicons/react/24/outline';
import { updateGloablAudioSettings } from '../slice/audioSettingSlice';
import { isApprovalRequired } from 'helpers/prechecks';
import { Precheck, Session } from 'globals/interfaces';
import { hasUnreadMessages } from 'helpers/messages';
import { TABS, mediaSettingsIcons } from './constant';
import { CurrentFeed, updateGlobalMediaSettings } from 'common/slice/mediaSettingSlice';
import { RecordingTypes, SessionStatus } from 'globals/enums';
import { setSelectedAttendees } from 'common/slice/activeAttendeeSlice';
import { getAvailableGlobalSettings, isSessionEnded } from 'helpers/session';
import { filter, map } from 'lodash';
import { hasUnreadCriticalAlerts } from 'helpers/alerts';
import { getSessionCompletedAt, getSessionJoinedAt } from 'helpers/sessionDetails';

interface Props {
  attendees: Attendee[];
  sessions: Session[];
}

const RightPanel = ({ attendees, sessions }: Props) => {
  const [isAllAttendeeSelected, setIsAllAttendeeSelected] = useState(false);
  const dispatch = useAppDispatch();
  const { attendee_id: attendeeId, selectedAttendees } = useAppSelector(
    (state) => state.activeAttendee,
  );
  const { previousMuteSelection } = useAppSelector((state) => state.audioSettings);

  const activeSessions: string[] = map(
    filter(
      sessions,
      (session: Session) =>
        selectedAttendees.includes(session.attendee_id) && !isSessionEnded(session.session_status),
    ),
    (session) => session.session_id,
  );

  const availableGlobalSettings: RecordingTypes[] = getAvailableGlobalSettings(
    selectedAttendees,
    sessions,
  );

  const selectAllAttendee = () => {
    setIsAllAttendeeSelected(!isAllAttendeeSelected);
    dispatch(
      setSelectedAttendees(
        isAllAttendeeSelected ? [] : attendees.map((item: Attendee) => item.attendee_id),
      ),
    );
  };

  const toggleAttendeeSelection = (attendeeId: number) => {
    const isSelected = selectedAttendees.includes(attendeeId);
    if (isSelected) {
      dispatch(
        setSelectedAttendees(selectedAttendees.filter((selected) => selected !== attendeeId)),
      );
    } else {
      dispatch(setSelectedAttendees([...selectedAttendees, attendeeId]));
    }
  };

  const handleAudioSettingChange = (audioStatus: boolean) => {
    const payload = {
      attendeeIds: isAllAttendeeSelected ? 'all' : selectedAttendees,
      audioStatus,
    };
    //@ts-ignore
    dispatch(updateGloablAudioSettings(payload));
  };

  const handleMediaSettingChange = (value) => {
    const payload = {
      attendeeIds: isAllAttendeeSelected ? 'all' : selectedAttendees,
      currentFeed: value as CurrentFeed,
    };
    //@ts-ignore
    dispatch(updateGlobalMediaSettings(payload));
  };

  const handleAnnouncement = () => {
    dispatch(openModal({ type: 'announcement', activeSessions }));
  };

  const renderMediaSettings = () => {
    return (
      <>
        {mediaSettingsIcons.map(
          (icon, index) =>
            (availableGlobalSettings.includes(icon.value as RecordingTypes) ||
              icon.value === 'auto-rotate') && (
              <icon.Icon
                key={index}
                title={icon.title}
                className={`h-4 w-4 cursor-pointer min-w-max hover:text-blue-400`}
                onClick={() => handleMediaSettingChange(icon.value)}
              />
            ),
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex items-center justify-between mx-4">
        <div className="flex items-center justify-between h-10 gap-5 min-h-40px">
          <input
            className="mr-2 cursor-pointer"
            type="checkbox"
            onChange={selectAllAttendee}
            checked={isAllAttendeeSelected}
            data-testid="select-all-checkbox"
          />
          {!!selectedAttendees?.length && (
            <>
              {previousMuteSelection ? (
                <SpeakerXMarkIcon
                  className="w-4 h-4 cursor-pointer hover:text-blue-400"
                  onClick={(event) => handleAudioSettingChange(!previousMuteSelection)}
                />
              ) : (
                <SpeakerWaveIcon
                  className="w-4 h-4 cursor-pointer hover:text-blue-400"
                  onClick={(event) => handleAudioSettingChange(!previousMuteSelection)}
                />
              )}
              {renderMediaSettings()}
            </>
          )}
        </div>
        {!!selectedAttendees?.length && !!activeSessions?.length && (
          <MegaphoneIcon
            onClick={handleAnnouncement}
            data-testid="announcement-btn"
            className="w-4 h-4 cursor-pointer hover:text-blue-400"
          />
        )}
      </div>
      <div className="relative flex flex-row" style={{ height: 'calc(100% - 40px)' }}>
        <div className="w-2/5 pb-8 overflow-auto">
          {attendees?.map((attendee: Attendee, index: number) => {
            const session = sessions?.find((item) => item.attendee_id === attendee.attendee_id);
            const prechecks: Precheck[] = session?.pre_check
              ? Object.values(session.pre_check)
              : [];
            const isApprovalPending = isApprovalRequired(prechecks, session?.configuration);
            const messages = session?.messages ? Object.values(session.messages) : [];
            const alerts = session?.alerts ? Object.values(session.alerts) : [];
            const sessionCompletedAt = getSessionCompletedAt(alerts);
            const sessionJoinedAt = getSessionJoinedAt(alerts);
            const isChatUnread = hasUnreadMessages(messages);
            const isCriticalAlertUnread = hasUnreadCriticalAlerts(alerts);
            return (
              <span
                className={`${
                  attendeeId === attendee.attendee_id &&
                  session?.session_status !== SessionStatus.NoShow
                    ? 'bg-slate-200'
                    : session?.session_status === SessionStatus.NoShow
                    ? 'bg-red-200'
                    : ''
                } rounded-md flex items-center mx-2 justify-around h-10 mt-0.5 px-2`}
                key={attendee.attendee_id}
              >
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  checked={
                    selectedAttendees.includes(attendee.attendee_id) &&
                    session?.session_status !== SessionStatus.NoShow
                  }
                  onChange={() => toggleAttendeeSelection(attendee.attendee_id)}
                  data-testid={`${attendee.attendee_id}_checkbox`}
                  disabled={session?.session_status === SessionStatus.NoShow}
                />
                <AttendeePane
                  sessionCompletedAt={sessionCompletedAt}
                  sessionJoinedAt={sessionJoinedAt}
                  tooltipId={`list_tooltip_${index}`}
                  isApprovalPending={isApprovalPending}
                  attendee={attendee}
                  isChatUnread={isChatUnread}
                  sessionId={session?.session_id}
                  sessionUuid={session?.session_uuid}
                  sessionStatus={session?.session_status}
                  isCriticalAlertUnread={isCriticalAlertUnread}
                  isPaused={session?.pause}
                  isRightPanel={true}
                />
              </span>
            );
          })}
        </div>
        <div className={`w-3/5 flex flex-col bg-slate-100 flex-grow h-full `}>
          <TabPane tabs={Object.values(TABS)} sessions={sessions} attendeeId={attendeeId} />
        </div>
      </div>
    </div>
  );
};

export default RightPanel;
