import { useCreateStreamMutation } from 'common/api/proctor';
import { StreamingProvider } from 'globals/enums';
import { handleError } from 'helpers/sentry';
import getRoom from 'helpers/stream';
import { RemoteParticipant } from 'livekit-client';
import { useEffect, useState } from 'react';
import Video, { RemoteTrack } from 'twilio-video';

export interface ParticipantData {
  session_id: string;
  primary: {
    tracks: RemoteTrack[];
  };
  secondary: {
    tracks: RemoteTrack[];
  };
}

const useLiveStream = (participant_group_id) => {
  const [createStream] = useCreateStreamMutation();
  const [participants, setParticipants] = useState<ParticipantData[]>([]);

  const addParticipant = (participant: Video.RemoteParticipant, track: Video.RemoteTrack) => {
    const [name, session_id, type] = participant.identity.split('_');
    if (name !== 'host') {
      //@ts-ignore
      setParticipants((prev) => {
        const existingParticipantIndex = prev.findIndex((p) => p.session_id === session_id);
        if (existingParticipantIndex !== -1) {
          const updatedParticipants = [...prev];
          const existingParticipant = updatedParticipants[existingParticipantIndex];

          if (type === 'primary') {
            existingParticipant.primary.tracks.push(track);
          } else if (type === 'secondary') {
            existingParticipant.secondary.tracks.push(track);
          }
          return updatedParticipants;
        } else {
          const newParticipant = {
            session_id,
            primary: {
              tracks: type === 'primary' ? [track] : [],
            },
            secondary: {
              tracks: type === 'secondary' ? [track] : [],
            },
          };
          return [...prev, newParticipant];
        }
      });
    }
  };

  const updateParticipant = (
    participant: RemoteParticipant | Video.RemoteParticipant,
    track: any,
  ) => {
    const [id, session_id, type] = participant.identity.split('_');
    setParticipants((prev) => {
      const existingParticipantIndex = prev.findIndex((p) => p.session_id === session_id);
      if (existingParticipantIndex !== -1) {
        const updatedParticipants = [...prev];
        const existingParticipant = updatedParticipants[existingParticipantIndex];
        if (type === 'primary') {
          const { name, kind, source } = track;
          if (name === 'screenshare' || name === 'screen_share' || source === 'screen_share') {
            const tracks = existingParticipant.primary.tracks.filter(
              (item: any) =>
                item?.name !== 'screenshare' &&
                item?.name !== 'screen_share' &&
                item?.source !== 'screen_share',
            );
            existingParticipant.primary.tracks = tracks;
          } else if (kind === 'video') {
            const tracks = existingParticipant.primary.tracks.filter(
              (a: any) =>
                (a.kind === 'video' && a.source === 'screen_share') ||
                (a.kind === 'video' && a.name === 'screenshare') ||
                a.kind === 'audio',
            );
            existingParticipant.primary.tracks = tracks;
          } else {
            const tracks = existingParticipant.primary.tracks.filter(
              (item) => item?.kind !== 'audio',
            );
            existingParticipant.primary.tracks = tracks;
          }
        } else {
          const tracks = existingParticipant.secondary.tracks.filter(
            (item) => item?.kind !== 'video',
          );
          existingParticipant.secondary.tracks = tracks;
        }
        return updatedParticipants;
      }
      return prev;
    });
  };

  useEffect(() => {
    if (participant_group_id) {
      const initializeLiveStream = async () => {
        try {
          const streamObject = await createStream({
            participant_group_id,
          }).unwrap();

          const room: any = await getRoom(streamObject);

          room.on('trackSubscribed', (track, publication, participant) => {
            addParticipant(participant, track);
          });

          room.on('trackUnsubscribed', (track, publication, participant) => {
            updateParticipant(participant, track);
          });

          if (streamObject?.video_provider === StreamingProvider.LiveKit) {
            room.remoteParticipants.forEach((participant) => {
              participant.trackPublications?.forEach((publication) => {
                if (publication.isSubscribed) {
                  const track = publication.track;
                  addParticipant(participant, track);
                }
              });
            });
          } else {
            room.participants.forEach((participant) => {
              participant.tracks?.forEach((publication) => {
                if (publication.isSubscribed) {
                  const track = publication.track;
                  addParticipant(participant, track);
                }
              });
            });
          }
        } catch (error) {
          handleError(error);
        }
      };

      initializeLiveStream();
    }
  }, [participant_group_id]);
  return participants;
};

export default useLiveStream;
